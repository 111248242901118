<template>
  <div class="page-container">
    <StepCounter v-if="stepCounterList" />
    <div class="enquiry-form-container">
      <DynamicForm
        :title="formDictionary.title"
        :subtitle="formDictionary.subtitle"
        :subtitleWithGoBackAction="formDictionary.subtitleWithGoBackAction"
        :submitButtonText="formDictionary.submitButtonText"
        :form="currentSection"
        :loading="loading"
        :submitState="submitState"
        @submit="submit"
        @previous-click="handlePreviousClick"
      />
    </div>
  </div>
</template>

<script>
import { stepCounterGetters, SET_STEP_COUNTER_LIST, SET_ACTIVE_STEP_INDEX, RESET_STEP_COUNTER_STATE } from '@/store/modules/goalsStepCounter/routines'
import { legacyLoansDataGetters, UPDATE_LEGACY_LOANS_DATA } from '@/store/modules/legacyLoansData/routines'
import { dynamicFormListGetters, updateDynamicFormListRoutine, checkDynamicFormListUpdatedRoutine } from '@/store/modules/dynamicFormList/routines'
import { enquiryFormFieldsRoutine, formFieldsGetters } from '@/store/modules/formFields/routines'
import { currentFormNameRoutine, currentFormNameGetters } from '@/store/modules/currentFormDetails/routines'
import { getFormStructure, getFormFields, saveForm, removeLegacyLoansForm } from '@/api/goals'
import { pageOverlayRoutine, pageOverlayGetters } from '@/store/modules/pageOverlay/routines'
import { putLegacyLoansFundingApplication } from '@/api/fundingApplication'
import { userRoutine } from '@/store/modules/auth/routines'
import { companyGetters } from '@/store/modules/company/routines'
import whitelabelConfig from '@/config/whitelabel'
import DynamicForm from '@/components/dynamicForms/DynamicForm'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import { amplitudeEvents } from '@/utils/constants'
import StepCounter from '@/components/StepCounter'
import deepLinking from '@/mixins/deepLinking'
import formatValue from '../utils/formatValue'
import { mapActions, mapGetters } from 'vuex'
import mapsFields from '@/mixins/mapsFields'
import { toNumber } from '@/utils/currency'
import notifies from '@/mixins/notifies'
import { getUser } from '@/api/user'
import { setFormFilePayload, uploadFormFile } from '@/utils/utils'
import { formMetaDataGetters, updateFormFileToDeleteRoutine, updateFormFileToUploadRoutine } from '@/store/modules/formMetaData/routines'
import { deleteDocument } from '@/api/document'
import { toastTopCenterOptions } from '@/config/vue-toast'
import { getMetaData } from '@/api/metaData'

export default {
  name: 'EnquiryForm',
  mixins: [mapsFields, notifies, deepLinking, amplitudeTracking],
  components: { DynamicForm, StepCounter },
  data() {
    return {
      countryName: whitelabelConfig.country.defaultCountry,
      currentSectionFormattedfieldValues: {},
      formSectionToUse: null,
      isFirstSignIn: true,
      currentSection: [],
      currentValues: {},
      formRoute: '',
      mockedForm: null,
      formsStatus: {},
      finalForms: {},
      loading: true,
      formData: {},
      formList: [],
      submitState: {
        loading: false,
        loadingPercentage: 0
      },
      formDictionary: {
        title: '',
        subtitle: 'We ask these questions so that we can match you with providers in our marketplace.',
        subtitleWithGoBackAction: {
          text: ' Or choose another funding type',
          action: 'goBack'
        },
        submitButtonText: ''
      },
      stepCounterItems: null
    }
  },
  props: {
    scroll: {
      type: Function
    }
  },
  computed: {
    ...mapGetters({
      getEnquiryFormFields: formFieldsGetters.ENQUIRY_FORM_FIELDS,
      dynamicFormList: dynamicFormListGetters.DYNAMIC_FORM_LIST,
      formFileToUpload: formMetaDataGetters.FORM_FILE_TO_UPLOAD,
      formFileToDelete: formMetaDataGetters.FORM_FILE_TO_DELETE,
      legacyLoansForm: legacyLoansDataGetters.LEGACY_LOANS_DATA,
      stepCounterList: stepCounterGetters.STEP_COUNTER_LIST,
      activeStepIndex: stepCounterGetters.ACTIVE_STEP_INDEX,
      currentFormName: currentFormNameGetters.CURRENT_FORM_NAME,
      isPageOverlayOpen: pageOverlayGetters.IS_PAGE_OVERLAY_OPEN,
      companyId: companyGetters.COMPANY_ID
    })
  },
  methods: {
    ...mapActions({
      setEnquiryFormFields: enquiryFormFieldsRoutine.TRIGGER,
      requestUser: userRoutine.TRIGGER,
      checkDynamicFormListUpdated: checkDynamicFormListUpdatedRoutine.TRIGGER,
      setFormFileToUpload: updateFormFileToUploadRoutine.TRIGGER,
      setFormFileToDelete: updateFormFileToDeleteRoutine.TRIGGER,
      resetStepCounterState: RESET_STEP_COUNTER_STATE,
      updateLegacyLoansData: UPDATE_LEGACY_LOANS_DATA,
      setStepCounterList: SET_STEP_COUNTER_LIST,
      setActiveIndex: SET_ACTIVE_STEP_INDEX,
      setCurrentFormName: currentFormNameRoutine.TRIGGER,
      setPageOverlay: pageOverlayRoutine.TRIGGER,
      updateDynamicFormList: updateDynamicFormListRoutine.TRIGGER

    }),
    async initializeForm() {
      this.setEnquiryFormFields({})
      await this.updateDynamicFormList()
      this.formRoute = this.$route.params?.formRoute
      let currentSection
      if (this.formRoute) {
        currentSection = this.dynamicFormList?.find(form => form.route === this.formRoute)
      } else {
        currentSection = this.dynamicFormList?.find(form => form.name === this.currentFormName)
      }

      if (whitelabelConfig.journey.disabledGoals.includes(currentSection?.name)) {
        return this.$router.replace({ name: 'goals' })
      }

      if (this.companyId) {
        const { data } = await getFormStructure(this.companyId, currentSection.formId)
        this.formData = data
        this.formDictionary.title = `${data.displayName} funding requirements`
        this.formSectionToUse = data.formSections[0].name
      } else {
        const { data } = await getFormFields(currentSection?.formId)
        this.formData = data
        this.formDictionary.title = `${data.displayName} funding requirements`
        this.formSectionToUse = data.formSections[0].name
      }

      this.formatFormSections()
      this.initializeSectionStatus()
      this.checkForAutoSubmit()
      this.loading = false
    },
    formatFormSections() {
      const finalFormToSave = {}
      if (!this.formData) return

      // eslint-disable-next-line no-unused-expressions
      this.formData?.formSections.forEach(section => {
        finalFormToSave[section.name] = this.formatSectionsFieldValues(section.formFields)

        this.formatFormSectionsForPayload(section.name, section.formFields)
      })

      this.finalForms = finalFormToSave
    },
    checkForAutoSubmit() {
      if (!this.finalForms[this.formSectionToUse].length) {
        this.submit(this.finalForms[this.formSectionToUse])
      }
    },
    formatSectionsFieldValues(sectionToFormat) {
      sectionToFormat = sectionToFormat
        .map(field => {
          return {
            ...field,
            ...(field.dependencies && { dependenciesList: field.dependencies.map(field => field.field) }),
            value: this.getFieldInitialValue(field)
          }
        })
        .filter(field => !!field && !field.disabled)

      if (!whitelabelConfig.forms.updatedGoalsFields.length && !whitelabelConfig.forms.hasCurrentlyBankOptionsChanged) return sectionToFormat

      return this.applyWhitelabelConfigsToSection(sectionToFormat)
    },
    applyWhitelabelConfigsToSection(sectionToFormat) {
      return sectionToFormat
        .map(field => {
          const updatedFieldValues = whitelabelConfig.forms.updatedGoalsFields[field.fieldId]

          // If we have a prefilled value coming through, use that rather than config default.
          if (field.value && updatedFieldValues?.value) {
            updatedFieldValues.value = field.value
          }

          if (whitelabelConfig.forms.hasCurrentlyBankOptionsChanged && field.metadata?.optionsType === 'banks') {
            field.options = whitelabelConfig.forms.currentlyBankOptions
          }

          return { ...field, ...updatedFieldValues }
        })
        .filter(field => !field.disabled)
    },
    formatFormSectionsForPayload(sectionName, formSection) {
      const fieldsValuesToSave = []

      formSection.forEach(field => {
        fieldsValuesToSave.push({
          fieldId: field.fieldId,
          value: this.getFieldInitialValue(field)
        })
      })

      const filteredValues = fieldsValuesToSave.filter(field => field.value !== null)
      if (!filteredValues.length) return

      this.currentSectionFormattedfieldValues = {
        [sectionName]: filteredValues
      }
    },
    getFieldInitialValue(field) {
      if (!field) return

      const section = this.formData?.formSections.find(section => section.name === this.formSectionToUse)
      const value = section.formFields.find(f => f.fieldId === field.fieldId)?.value || null
      const updatedFieldValues = whitelabelConfig.forms.updatedGoalsFields[field.fieldId]

      if (updatedFieldValues?.value) {
        return formatValue(updatedFieldValues.value, field.dataType, field.options)
      }

      if (value) {
        return formatValue(value, field.dataType, field.options)
      }

      return null
    },
    initializeSectionStatus() {
      this.formsStatus = {}
      if (!this.formData.formSections) return
      return this.formData?.formSections.forEach(section => (this.formsStatus[section.name] = false))
    },
    getNextSection() {
      const formSections = this.formData?.formSections
      return formSections[formSections.findIndex(section => section.name === this.formSectionToUse) + 1]?.name || null
    },
    handlePreviousClick() {
      const prevSection = this.formData?.formSections[this.formData?.formSections.findIndex(section => section.name === this.formSectionToUse) - 1]?.name || null
      if (prevSection) {
        this.formSectionToUse = prevSection
        return
      }
      return this.$router.go(-1)
    },
    checkIfEditing() {
      return !!this.user.goalsCompleted.find(goal => goal.formId === this.formData.formId)
    },
    async submit(currentSection) {
      this.submitState = { loading: true }

      const matchesPageByType = {
        loan: 'loan-matches',
        equity: 'equity-matches',
        grant: 'grant-matches'
      }

      this.checkForDeepLinkShift()
      this.formatCurrentSectionFinalFieldValues(currentSection)

      this.handleFormsStatus(this.formSectionToUse, true)

      try {
        const enquiryFormFieldsSections = this.buildEnquiryFormFieldsSections()
        const nextSection = this.getNextSection()

        this.setEnquiryFormFields(enquiryFormFieldsSections)

        if (nextSection) {
          this.formSectionToUse = nextSection
          return
        }

        let sections =
          this.getEnquiryFormFields?.sections?.filter(section => this.formData.formSections.find(formDataSection => formDataSection.formSectionId === section.id)) || []

        sections = await this.handleFileUpload(sections)

        // if this is the signup journey
        if (this.companyId === null) {
          // replace the structure of enquiryFormFields for form submission & routing logic after signup (src/store/modules/auth/actions.js)
          if (this.formData?.type === 'Loan') {
            this.setCurrentFormName(this.formData?.name)
          }
          this.setEnquiryFormFields({
            sections: sections,
            nextRoute: this.handleRedirectToNextRoute(matchesPageByType[this.formData.type.toLowerCase()]),
            formId: this.formData.formId,
            name: this.formData.displayName
          })
          return this.$router.push({
            name: 'goals-form-sign-up',
            params: {
              formRoute: this.$route.params.formRoute
            }
          })
        }

        // If user is logged in
        const loggedInPayload = { isUpdate: this.checkIfEditing(sections[0].id), isFormCompleted: this.checkIfAllSectionsAreComplete(), sections: sections }
        const res = await saveForm(this.companyId, this.formData.formId, loggedInPayload)

        // remove legacy loans form, update legacy application removing fundingPurposes, remove legacyLoansFormData from local storage
        if (this.legacyLoansFormData && res.status === 200) {
          const res = await removeLegacyLoansForm(this.companyId)
          if (res.status === 200) {
            const updatedLegacyLoansFormData = JSON.parse(this.legacyLoansFormData)
            updatedLegacyLoansFormData.fundingPurposes = []
            const res = await putLegacyLoansFundingApplication(JSON.stringify(updatedLegacyLoansFormData))
            if (res.status === 200) {
              this.updateLegacyLoansData(null)
              localStorage.removeItem('legacyLoansFormData')
            }
          }
        }
        await this.requestUser()
        this.handleDeepLinkRedirect()
      } catch (error) {
        console.error(error)
      } finally {
        this.submitState = { loading: false }
      }

      if (this.formRoute) {
        this.$router.push({ name: matchesPageByType[this.formData.type.toLowerCase()] })
      }
      // matches page overlay form
      if (this.formData?.type === 'Loan') {
        this.setCurrentFormName(this.formData?.name)
      }

      if (this.isPageOverlayOpen) {
        if (window.dataLayer) window.dataLayer.push({ event: 'match_edit' })
        if (this.$route?.path === '/dashboard') {
          this.$emit('refetch-products', this.currentFormName)
          await this.setPageOverlay(!this.isPageOverlayOpen)
          await this.$router.push({ name: 'matches' })
        } else {
          this.$emit('refetch-products', this.currentFormName)
          await this.setPageOverlay(!this.isPageOverlayOpen)
        }
        if (this.$route?.path === '/matches/loans') this.scroll('left')
      }
    },
    formatCurrentSectionFinalFieldValues(section) {
      this.currentSectionFormattedfieldValues = this.finalForms[this.formSectionToUse].map(field => {
        if (!field.name) return
        let value = section[field.name] || ''

        // Currencies should be converted to integers
        if (field.type === 'text' && field.dataType === 'integer') {
          value = toNumber(section[field.name]).toString() || ''
        }
        return {
          fieldId: field.fieldId,
          value: value
        }
      })
    },
    handleFormsStatus(form, status) {
      this.formsStatus[form] = status
    },
    buildEnquiryFormFieldsSections() {
      const prevSections = this.getEnquiryFormFields?.sections
      const newSection = {
        id: this.getFormSectionInfo().formSectionId,
        isCompleted: true,
        fieldValues: this.currentSectionFormattedfieldValues
      }
      return { isFormCompleted: this.checkIfAllSectionsAreComplete(), sections: [...(prevSections || []), newSection] }
    },
    checkIfAllSectionsAreComplete() {
      // this.formStatus example = {'goalName: boolean', 'aboutBusiness': boolean}
      return Object.keys(this.formsStatus).every(key => this.formsStatus[key])
    },
    getFormSectionInfo() {
      return this.formData.formSections.filter(section => section.name === this.formSectionToUse)[0]
    },
    trackLloydsGrants() {
      if (this.$route?.path === '/goal/grant') {
        this.trackLloydsGrantFinderEvent(amplitudeEvents.pageViews.LLOYDS_BANK_GRANT_FINDER, 'Your funding')
      }

      if (this.$route?.path === '/goal/grant' && this.formSectionToUse === 'aboutBusiness') {
        this.trackLloydsGrantFinderEvent(amplitudeEvents.pageViews.LLOYDS_BANK_GRANT_FINDER, 'Your business')
      }
    },
    async handleFileUpload(sections) {
      if (!this.companyId || !this.formFileToUpload) return sections

      this.submitState = { loading: true, loadingPercentage: 75 }
      this.deleteFile()

      const fileUploadResponse = await uploadFormFile(this.companyId, this.formFileToUpload, this.formFileToUpload?.fileName)
      if (fileUploadResponse) {
        this.setFormFileToUpload(null)
        return setFormFilePayload({ sections }, fileUploadResponse)?.sections || sections
      }

      this.submitState = { loading: true, loadingPercentage: 90 }
      return sections
    },
    async deleteFile() {
      if (this.companyId && this.formFileToDelete) {
        await deleteDocument(this.formFileToDelete)
        this.setFormFileToDelete(null)
      }
    },
    async fetchOptions(entities, country) {
      try {
        const { data } = await getMetaData([entities], country)
        const options = data[entities]
        if (options) {
          return options
        }
      } catch (error) {
        this.$toasted.show('Sorry, something went wrong..', { ...toastTopCenterOptions, type: 'error' })
      }
    },
    async fetchOptionsForDependantFields(formName) {
      // handles fetching options for fields which depend on their parents answer
      const currentFormFields = this.formData.formSections.find(formSection => formSection.name === formName)?.formFields
      for (const field of currentFormFields) {
        const parentFieldName = field.metadata?.requiredParentField
        if (!parentFieldName) continue
        const parentValue = currentFormFields.find(field => field.name === parentFieldName)?.value
        if (parentValue) {
          const entities = field?.metadata?.optionsType
          const country = parentValue
          if (!country || !entities) {
            return this.$toasted.show('Sorry, something went wrong..', { ...toastTopCenterOptions, type: 'error' })
          }
          field.options = await this.fetchOptions(entities, country)
        }
      }
    },
    checkForActiveStepIndex() {
      if (this.isPageOverlayOpen) {
        if (this.activeStepIndex === 0 && this.stepCounterItems?.length === 2) {
          this.formDictionary.submitButtonText = 'Continue to the next page'
        } else {
          this.formDictionary.submitButtonText = 'Submit'
        }
      }
    }
  },
  async created() {
    this.resetStepCounterState()
    if (this.companyId) {
      const { data } = await getUser()
      this.user = data
      this.isFirstSignIn = this.user.goalsCompleted.length === 0
    }
  },
  async mounted() {
    this.trackLloydsGrants()
    await this.initializeForm()
    await this.checkDynamicFormListUpdated()
  },
  unmounted() {
    this.finalForms = {}
  },
  watch: {
    async formSectionToUse(formName) {
      // Google Analytics tracking
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'pageview',
          pageviewType: 'Dynamic Form',
          formName
        })
      }
      await this.fetchOptionsForDependantFields(formName)

      this.trackLloydsGrants()
      this.formatFormSections()
      this.setActiveIndex(
        this.formData?.formSections?.findIndex(section => {
          return section.name === formName
        }) || 0
      )
      this.currentSection = this.mockedForm || this.finalForms[formName]
    },
    async formData(newForm) {
      this.stepCounterItems =
        newForm?.formSections.map(section => {
          return section.displayName
        }) || []
      if (!this.companyId) this.stepCounterItems.push('Sign up', 'Funding options')

      if (!this.isPageOverlayOpen) this.formDictionary.submitButtonText = 'Continue to the next page'

      this.checkForActiveStepIndex()

      this.setStepCounterList(this.stepCounterItems)
      this.setActiveIndex(0)
    },
    async isPageOverlayOpen(newValue) {
      this.loading = true
      newValue && (await this.initializeForm())
      this.loading = false
    },
    async activeStepIndex() {
      this.checkForActiveStepIndex()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';

.enquiry-form-container {
  width: 1210px;
  margin: auto;
  padding-bottom: 16px;

  @media only screen and (max-width: 1256px) {
    width: 100%;
    padding: 0 24px;
  }

  @media only screen and (max-width: 830px) {
    width: 100%;
    padding: 10px;
  }
}
</style>
