<template>
  <div>
    <div v-if="isPageOverlayOpen" class="tabs">
      <em class="material-icons" @click="closeOverlay()">arrow_back</em>
      <span v-for="(step, index) in steps || stepCounterList" :key="step">
        <span class="tab" :class="{ active: index === activeStepIndex }">{{ `${index + 1}. ${step}` }}</span>
      </span>
    </div>
    <div class="steps-container" v-else>
      <div class="steps">
        <div v-for="(step, index) in steps || stepCounterList" :key="step" class="step" :class="{ active: steps ? index < activeStepIndex : index < activeStepIndex }">
          <div class="circle" :class="{ active: index <= activeStepIndex }" />
          <span class="title" :class="{ active: index <= activeStepIndex }">{{ `${index + 1}. ${step}` }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { stepCounterGetters } from '@/store/modules/goalsStepCounter/routines'
import { pageOverlayGetters, pageOverlayRoutine } from '@/store/modules/pageOverlay/routines'

export default {
  name: 'StepCounter',
  props: {
    steps: {
      required: false,
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      stepCounterList: stepCounterGetters.STEP_COUNTER_LIST,
      activeStepIndex: stepCounterGetters.ACTIVE_STEP_INDEX,
      isPageOverlayOpen: pageOverlayGetters.IS_PAGE_OVERLAY_OPEN
    })
  },
  methods: {
    closeOverlay() {
      this.$store.dispatch(pageOverlayRoutine.TRIGGER, false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/_mixins.scss';
@import '@/assets/styles/swoop/buttons.scss';

.steps-container {
  @include flex-display(row, center, center);

  width: 100%;
  height: 88px;
  margin-bottom: 32px;
  background-color: var(--color-primary-100);

  .steps {
    @include flex-display(row, center, center);

    width: 100%;
    max-width: 1210px;
    height: 88px;

    .step {
      width: 100%;
      margin-bottom: 16px;
      border-bottom: 2px solid var(--color-primary-200);

      .circle {
        width: 10px;
        height: 10px;
        position: absolute;
        margin-top: -4px;
        border: 2px solid var(--color-primary-200);
        border-radius: 50%;
        background-color: $color-white;

        &.active {
          border: 2px solid var(--color-secondary-500);
          background-color: var(--color-secondary-500);
        }
      }

      .title {
        position: absolute;
        max-width: 100%;
        margin-top: 8px;
        margin-left: 1.5px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.35px;
        color: var(--color-primary-300);

        &.active {
          color: var(--color-primary-500);
        }
      }

      &.active {
        border-bottom: 2px solid var(--color-secondary-500);
      }
    }
  }
}

@media only screen and (max-width: 1256px) {
  .steps-container {
    padding: 0 24px;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 830px) {
  .steps-container {
    height: 42px;
    margin-top: 4px;
    margin-bottom: 16px;
    padding: 0 16px;

    .steps {
      height: 42px;
      max-width: 100%;
      padding: 16px;

      .step {
        margin-bottom: 0px;

        span {
          display: none;
        }

        &:last-child {
          width: auto;

          .circle {
            margin-left: -10px;
          }
        }
      }
    }
  }
}

.tabs {
  @include flex-display(row, center, flex-start);
  border-bottom: 1px solid var(--color-neutral-200);

  .material-icons {
    background-color: var(--color-primary-100);
    padding: 20px 10px;

    @media only screen and (max-width: $breakpoint-sm-max) {
      padding: 20px 5px;
    }
  }

  .tab {
    font-size: 13px;
    font-weight: bold;
    color: var(--color-primary-300);
    text-decoration: none;
    border-radius: 0;
    white-space: pre;

    &:hover {
      color: $color-primary;
    }

    &:active,
    &.active {
      color: $color-primary;
      border-bottom: 3px solid var(--color-secondary-500);
    }
  }
}
</style>
