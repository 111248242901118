<template>
  <div class="page-overlay" :class="{ open: isPageOverlayOpen, closed: !isPageOverlayOpen }">
    <div class="page">
      <div class="outer-layer" @click="closePageOverlay()"></div>
      <div class="page-content" @keydown.esc="closePageOverlay">
        <div class="restrict-width">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { pageOverlayRoutine, pageOverlayGetters } from '@/store/modules/pageOverlay/routines'

export default {
  methods: {
    ...mapActions({
      setPageOverlay: pageOverlayRoutine.TRIGGER
    }),
    closePageOverlay() {
      this.setPageOverlay(!this.isPageOverlayOpen)
    }
  },
  computed: {
    ...mapGetters({
      isPageOverlayOpen: pageOverlayGetters.IS_PAGE_OVERLAY_OPEN
    })
  },
  watch: {
    isPageOverlayOpen(value) {
      this.setPageOverlay(value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 100;

  &.closed {
    display: none;
  }
  &.open {
    width: 100%;

    .outer-layer {
      width: 20%;
      opacity: 0.7;
      background-color: var(--color-primary-500);

      @media only screen and (max-width: $breakpoint-xs-max) {
        width: 0;
      }
    }

    .page {
      width: 100%;
      z-index: 999;
      display: flex;

      @media only screen and (min-width: $breakpoint-xs-max) {
        /deep/.loading-bar {
          top: calc(50% - 40px);
          left: calc(60% - 40px);
        }
      }

      .page-content {
        width: 80%;
        height: 100vh;
        background-color: $color-white;
        opacity: 1;
        overflow-y: scroll;

        &:focus {
          outline: none;
          font-weight: bold;
        }

        @media only screen and (max-width: $breakpoint-xs-max) {
          width: 100%;
        }
      }
    }
  }
}
</style>
